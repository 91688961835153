.main {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.navholder {
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1vh;
  border-radius: 5px;
  border: 1px solid lightgrey;
  box-shadow: 0 3px 10px var(--color-secondary);
  height: 5vh;
  padding: 1vw;
  background: white;
}

.rightside {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5vw;
}

.heading {
  font-size: 2vw;
  font-weight: 600;
  color: var(--color-primary);
}

.menulink {
  font-family: var(--font-family);
  font-size: 1.5vw;
  font-weight: 400;
}

// for mobile devices
@media (min-width: 320px) and (max-width: 480px) {
  .heading {
    font-size: 4vw;
  }

  .menulink {
    font-size: 2.5vw;
  }
}
