.main {
  min-height: -webkit-fill-available;
  background: var(--page-background-color);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.leftimage {
  width: 35vw;
  height: 100vh;
  overflow-y: hidden;
}

.frontimage {
  width: 35vw;
  height: auto;
  filter: blur(3px);
  box-shadow: 0 3px 10px var(--color-secondary);
}

.mainholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.rightcontent {
  width: 65vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.introholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}

.firstheading {
  font-family: var(--font-family);
  font-size: var(--primary-heading);
  font-weight: 500;
  color: var(--color-primary);
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.anchortag:nth-child(1) {
  color: var(--color-dark);
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce;
  animation-timing-function: ease;
}

.anchortag:nth-child(2) {
  color: var(--color-dark);
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 1s;
}

.anchortag:nth-child(3) {
  color: var(--color-dark);
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 3s;
}

.socialicon {
  font-size: 2vw;
  margin: 0px;
}

.socialiconsholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  position: absolute;
  bottom: 2vh;
  right: 5vw;
}

.intropara {
  font-size: var(--first-paragraph);
  width: 40vw;
  text-align: center;
  margin: 0px;
  font-family: var(--font-family);
}

.buttonsholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.spacebutton {
  width: 20vw;
  padding: 2vh 2vw;
  background: var(--color-primary);
  color: var(--color-secondary);
  font-size: var(--button-fontsize);
  border-radius: 5px;
  border: none;
  font-family: var(--font-family);
  animation-duration: 1s;
  animation-iteration-count: 1;
  transform-origin: bottom;
}

.spacebutton:hover {
  cursor: pointer;
  animation-name: bounce;
  animation-timing-function: ease;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1vh);
  }
  100% {
    transform: translateY(0);
  }
}

.secondsection {
  height: 100vh;
  background: var(--color-primary);
}

.spaceheading {
  margin: 0px;
}

// for mobile devices
@media (min-width: 320px) and (max-width: 480px) {
  .mainholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
  }

  .leftimage {
    width: 100vw;
    height: 45vh;
    overflow-y: hidden;
  }

  .frontimage {
    width: 100vw;
    height: auto;
    filter: blur(3px);
    box-shadow: 0 3px 10px var(--color-secondary);
  }

  .rightcontent {
    width: 100vw;
    height: 42vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vh;
  }

  .intropara {
    font-size: var(--mobile-first-paragraph);
    width: 80vw;
    text-align: center;
    margin: 0px;
    font-family: var(--font-family);
  }

  .firstheading {
    font-size: var(--mobile-primary-heading);
  }

  .buttonsholder {
    gap: 3vh;
  }

  .spacebutton {
    width: 40vw;
    padding: 2vh 2vw;
    font-size: var(--mobile-button-fontsize);
  }
}

// for ipads and tablets
@media (min-width: 481px) and (max-width: 768px) {
}

//for small screens and laptops
@media (min-width: 769px) and (max-width: 1024px) {
}

// for Desktops and large screens
@media (min-width: 1025px) and (max-width: 1200px) {
}

// fot large screens
@media (min-width: 1201px) {
}
