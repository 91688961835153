@import url("https://fonts.googleapis.com/css2?family=Sono:wght@200;300;400;500;600;700;800&display=swap");

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--font-family);
}

:root {
  --color-primary: #aa1111;
  // --color-primary: #2e4dfb;
  --color-secondary: #ebebeb;
  --color-tertiary: #ffffff;
  --color-dark: #000000;

  --color-button-background: #f36045;
  --page-background-color: whitesmoke;
  --font-family: "Sono", sans-serif;
  --primary-heading: 4vw;
  --secondary-heading: 3vw;
  --third-heading: 2vw;
  --first-paragraph: 1.5vw;
  --button-fontsize: 1vw;
  --mobile-primary-heading: 6vw;
  --mobile-secondary-heading: 5vw;
  --mobile-third-heading: 4vw;
  --mobile-first-paragraph: 3.5vw;
  --mobile-button-fontsize: 3vw;
}
